export const ON_HOLD = "On Hold"
export const SLOW_DAY = "Slow Day"
export const IMPORTANT = "Important"
export const MEDIUM = "Medium"
export const ASAP = "ASAP"
export const HIGH_PRIORITY = "High Priority"
export const URGENT = "Urgent"
export const SEVERE = "Severe"
export const CRITICAL = "Critical"
export const EMERGENCY = "Emergency!"

export const TASK_DATE_FORMAT = "Do MMM YYYY"
export const ADD_NEW_CONTACT = "Add New Contact"
export const SEARCH_EXISTING_CONTACTS = "Search Existing Contacts"
export const PIPELINE = "pipeline"

export const taskStatusesData = [
  {
    label: "New",
    class: "new",
    icon: "app",
  },
  {
    label: "Claimed",
    class: "claimed",
    icon: "clock",
  },
  {
    label: "In Process",
    class: "in_progress",
    icon: "hourglass-split",
  },
  {
    label: "Complete Pending Verification",
    class: "pending_verification",
    icon: "check2-square",
  },
  {
    label: "Revisions",
    class: "revision",
    icon: "exclamation-triangle-fill",
  },
  {
    label: "Closed Successful",
    class: "closed_successful",
    icon: "flag-fill",
  },
  {
    label: "Specs / Clarification Needed",
    class: "clarification_needed",
    icon: "chat-fill",
  },
  {
    label: "Deliverables Required",
    class: "deliverables_required",
    icon: "cloud-arrow-up-fill",
  },
  {
    label: "Prerequisite / Dependency",
    class: "prerequisite",
    icon: "clock",
  },
]

export const taskUrgencyData = [
  {
    LABEL: "On Hold",
    CLASS: "on_hold",
    ICON: "moon",
  },
  {
    LABEL: "Slow Day",
    CLASS: "slowDay",
    ICON: "hourglass",
  },
  {
    LABEL: "Important",
    CLASS: "important",
    ICON: "star",
  },
  {
    LABEL: "Medium",
    CLASS: "medium",
    ICON: "star-fill",
  },
  {
    LABEL: "ASAP",
    CLASS: "asap",
    ICON: "heart-fill",
  },
  {
    LABEL: "High Priority",
    CLASS: "high_priority",
    ICON: "exclamation-lg",
  },
  {
    LABEL: "Urgent",
    CLASS: "urgent",
    ICON: "exclamation-circle-fill",
  },
  {
    LABEL: "Severe",
    CLASS: "severe",
    ICON: "exclamation-triangle-fill",
  },
  {
    LABEL: "Critical",
    CLASS: "critical",
    ICON: "fire",
  },
  {
    LABEL: "Emergency!",
    CLASS: "emergency",
    ICON: "fire",
  },
]

export const taskTimeData = [
  {
    LABEL: "15 Minutes",
    CLASS: "time_15_min",
  },
  {
    LABEL: "1 Hour",
    CLASS: "time_1_hr",
  },
  {
    LABEL: "2 Hours",
    CLASS: "time_2_hr",
  },
  {
    LABEL: "4 Hours",
    CLASS: "time_4_hr",
  },
  {
    LABEL: "1 Day",
    CLASS: "time_1_day",
  },
  {
    LABEL: "3 Days",
    CLASS: "time_3_day",
  },
  {
    LABEL: "1 Week",
    CLASS: "time_1_week",
  },
  {
    LABEL: "1 Month",
    CLASS: "time_1_month",
  },
  {
    LABEL: "1 Year",
    CLASS: "time_1_year",
  },
  {
    LABEL: "On-Going / Indefinite",
    CLASS: "time_on_going",
  },
]
