import * as React from "react"
import { Accordion } from "react-bootstrap"
import { useRef, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { getLoginUserId } from "../../../services/auth"
import DriveTable from "../../Drive/diveTable"
import DriveLazyLoad from "../../Drive/driveLazyLoad"
import { onGrabData } from "../../Drive/Service/driveListHelper"
import clsx from "clsx"
import AddInvoiceModal from "./addInvoiceModal"
import MobileAddNewDropdown from "../../Drive/MobileView/addNewDropdown"
import { isMobileView } from "../../../services/globalHelpers"

const FilesPanel = ({ driveAllId }) => {
  const triggerRef = useRef(null)
  const selectedDocId = driveAllId
  const [searchTerm, setSearchTerm] = useState("")

  const { data, loading } = DriveLazyLoad({
    triggerRef,
    onGrabData,
    selectedDocId,
    searchTerm,
    setSearchTerm,
  })

  const [uploading, setUploading] = useState(false),
    [selectedFile, setSelectedFile] = useState([]),
    [isAddInvoice, setIsAddInvoice] = useState(false),
    [addDoc, setAddDoc] = useState(false),
    [addSequence, setSequence] = useState(false),
    [addCode, setAddCode] = useState(false),
    [addFolder, setAddFolder] = useState(false),
    [addForm, setAddForm] = useState(false)

  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click()
  }

  const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key])

  const [uploadingData, setUploadingData] = useState([])

  const handleNewFileUpload = e => {
    const { files: newFiles } = e.target
    const updatedFiles = newFiles
    let existingData = [...uploadingData]

    for (const updatedFile of updatedFiles) {
      existingData.push({ name: updatedFile.name })
    }
    setUploadingData(existingData)

    handleSubmit(convertNestedObjectToArray(newFiles))
  }

  const handleSubmit = newFiles => {
    return new Promise(async resolve => {
      await AxiosInstance.post(
        `/drive/file-upload`,
        {
          user_id: getLoginUserId(),
          drive_all_files: newFiles,
          parent_id: driveAllId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(function (response) {
          if (response.status === 201) {
            console.log(response.status)
          }
          setUploading(false)
          window.location.reload()
          return response.data
        })
        .catch(function (error) {
          console.log(error)
        })
    })
  }

  return (
    <Accordion defaultActiveKey="0" id="files_panel">
      <Accordion.Item eventKey="1" className="panel panel-beige">
        <Accordion.Header>
          <span className="panel-heading-icon">
            <i className="bi bi-paperclip" />
          </span>
          <span className="panel-heading-title">Files</span>
        </Accordion.Header>
        <Accordion.Body id="files_container">
          <div className="row">
            {driveAllId && (
              <>
                <div className="col-lg-12">
                  <div className="float-end">
                    <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                      <li className="nav-item">
                        <button
                          type="button"
                          className="btn btn-primary btnUpload"
                          onClick={() => {
                            setIsAddInvoice(true)
                          }}
                        >
                          <i className="bi bi-plus-lg"></i>Add new
                        </button>
                      </li>
                      <li className="nav-item">
                        <button type="button" className="btn btn-secondary btnUpload" onClick={onButtonClick}>
                          <i className="bi bi-cloud-arrow-up-fill"></i>{" "}
                          <span className="d-none d-md-inline">Upload</span>
                        </button>
                        <input
                          type="file"
                          className="d-none"
                          ref={inputFile}
                          onChange={handleNewFileUpload}
                          multiple
                        />
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="drive-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#drive"
                          role="tab"
                          aria-controls="drive"
                          aria-selected="false"
                        >
                          <span data-bs-toggle="tooltip" data-bs-title="Drive">
                            <i className="bi bi-database-fill"></i>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="small-icon-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#small-icon"
                          role="tab"
                          aria-controls="small-icon"
                          aria-selected="false"
                        >
                          <span data-bs-toggle="tooltip" data-bs-title="Small Icons">
                            <i className="bi bi-ui-checks-grid"></i>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="large-icon-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#large-icon"
                          role="tab"
                          aria-controls="large-icon"
                          aria-selected="false"
                        >
                          <span data-bs-toggle="tooltip" data-bs-title="Large Icons">
                            <i className="bi bi-stop-fill"></i>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item nav-item-trash" role="presentation">
                        <a
                          className="nav-link"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="View Templates"
                        >
                          <i className="bi bi-journals"></i>
                        </a>
                      </li>
                      <li className="nav-item nav-item-trash" role="presentation">
                        <a
                          className="nav-link"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Trash"
                        >
                          <i className="bi bi-trash-fill"></i>
                        </a>
                      </li>
                    </ul>

                    {isMobileView() && (
                      <MobileAddNewDropdown
                        addDoc={addDoc}
                        addForm={addForm}
                        addSequence={addSequence}
                        addCode={addCode}
                        addFolder={addFolder}
                        uploadingData={uploadingData}
                        setUploadingData={setUploadingData}
                        isTaskPage={true}
                        driveAllId={driveAllId}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        triggerRef={triggerRef}
                      />
                    )}
                  </div>
                </div>
                <DriveTable
                  data={data}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                  parentId={driveAllId}
                  uploadingData={uploadingData}
                />
                <AddInvoiceModal
                  isAddInvoice={isAddInvoice}
                  setIsAddInvoice={setIsAddInvoice}
                  driveAllId={driveAllId}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  triggerRef={triggerRef}
                />
              </>
            )}
          </div>
          <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default FilesPanel
