import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Detail from "../../../components/Tasks/Detail/detail"
import ReduxWrapper from "../../../redux/reduxWrapper"

const TasksDetail = props => {
  return <PrivateRoute component={Detail} location={props.location} selectedId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<TasksDetail {...props} />} />
export default WrappedPage
